import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {request} from "../Request";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const SENDOTP = "sendOtp";
export const LOGOUT = "logout";
export const DELETE_TOKEN = "deleteToken";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const REMOVE_ERROR = "removeError";

const state = {
    errors: [],
    user: {},
    isAuthenticated: !!JwtService.getToken(),
    activeEventId: null,
    activeProperty: null,
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise(resolve => {
            context.commit(REMOVE_ERROR)
            ApiService.post("auth/sign/in", credentials)
                .then(({data}) => {
                    context.commit(SET_AUTH, data);
                    context.commit("GET_ACTIVE_PROPERTY_LIST", data.data.property[0]);
                    context.commit("GET_ACTIVE_EVENT_LIST", data.data.event[0]);
                    resolve(data);
                })
                .catch((error) => {
                    if (error.request && error.request.status && error.request.status === 422) {
                        context.commit(SET_ERROR, JSON.parse(error.request.responseText).errors);
                    } else {
                        if (error.request && error.request.status && error.request.status === 422) {
                            context.commit(SET_ERROR, JSON.parse(error.request.responseText).errors);
                        } else {
                            context.commit(SET_ERROR, error.errors);
                        }

                    }
                });
        });
    },
    [SENDOTP](context, credentials) {
        return new Promise(resolve => {
            context.commit(REMOVE_ERROR)
            ApiService.post("auth/send/otp", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    if (error.request && error.request.status && error.request.status === 422) {
                        context.commit(SET_ERROR, JSON.parse(error.request.responseText).errors);
                    } else {
                        if (error.request && error.request.status && error.request.status === 422) {
                            context.commit(SET_ERROR, JSON.parse(error.request.responseText).errors);
                        } else {
                            context.commit(SET_ERROR, error.errors);
                        }
                    }
                });
        });
    },
    [DELETE_TOKEN](context, credentials) {
        // return new Promise(resolve => {
        // ApiService.post("auth/logout", credentials)
        //     .then(({data}) => {
        context.commit(PURGE_AUTH);
        //     resolve(data);
        // })
        // .catch((error) => {
        //     if (error.request && error.request.status && error.request.status === 422) {
        //         context.commit(SET_ERROR, JSON.parse(error.request.responseText).errors);
        //     } else {
        //         context.commit(SET_ERROR, error.errors);
        //     }
        //
        // });
        // });
    },

    [REGISTER](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("login", credentials)
                .then(({data}) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    async [VERIFY_AUTH](context) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    [UPDATE_PASSWORD](context, payload) {
        const password = payload;

        return ApiService.put("password", password).then(({data}) => {
            context.commit(SET_PASSWORD, data);
            return data;
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_ERROR](state, error) {
        state.errors = [];
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user;
        state.errors = [];
        const {access_token} = state.user.data
        if (access_token) {
            JwtService.saveToken(JSON.stringify(state.user.data));
        }
    },
    [SET_PASSWORD](state, password) {
        state.user.password = password;
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = [];
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
