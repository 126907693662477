import axios from 'axios';
import {getToken, destroyToken} from "./jwt.service"

/**
 * Create an Axios Client with defaults
 */

let axiosInstance = axios.create();

axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['Content-Language'] = localStorage.getItem('app.settings.locale') || 'en-US';

axiosInstance.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params['property_id'] = localStorage.getItem("property_id") ? localStorage.getItem("property_id") : null;
    return config;
});

export const client = axiosInstance;

/**
 * Request Wrapper with default success/error actions
 */
export const request = function (options) {

    if (getToken()) {
        const user = getToken();
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
    }

    const onSuccess = function (response) {
        return response;
    }

    const onError = function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                setTimeout(() => {
                    destroyToken();
                    window.location.reload(true)
                }, 2000);

                return false;
            }
        } else {
        }

        return Promise.reject(error.response || error.message);
    }

    return axiosInstance(options)
        .then(onSuccess)
        .catch(onError);
}
