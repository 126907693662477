import Vue from "vue"

export const setProperty = () => {
    Vue.$toast.success('Selected Property Set Successfully!', {
        position: 'top-right'
    })
}

export const itemAdded = (message = null) => {
    Vue.$toast.success(message ? message + ' Item Added!' : ' Item Added!', {
        position: 'top-right'
    })
}

export const priceAddedToWalletSuccessfully = () => {
    Vue.$toast.success('Price Added To Wallet Successfully!', {
        position: 'top-right'
    })
}

export const contactSuccess = () => {
    Vue.$toast.success('Detail send to owner Successfully!', {
        position: 'top-right'
    })
}

export const itemUpdated = (message = null) => {
    Vue.$toast.success(message ? message + ' Item Updated!' : 'Item Updated!', {
        position: 'top-right'
    })
}

export const itemEditFails = () => {
    Vue.$toast.warning('Unable to find Item!', {
        position: 'top-right'
    })
}

export const itemDeleted = () => {
    Vue.$toast.error('Item Deleted!', {
        position: 'top-right'
    })
}

export const invitationSend = () => {
    Vue.$toast.success('Invitation Send Successfully!', {
        position: 'top-right'
    })
}

export const serverError = (message) => {
    Vue.$toast.error(message, {
        position: 'top-right'
    })
}

export const notFound = () => {
    Vue.$toast.error('Unable to Find Data!', {
        position: 'top-right'
    })
}

export const sendQuotation = () => {
    Vue.$toast.error('Quotation sent!', {
        position: 'top-right'
    })
}

export const notify = (message) => {
    Vue.$toast.success(message, {
        position: 'top-right'
    })
}

export const importSuccess = () => {
    Vue.$toast.success('Import file successfully', {
        position: 'top-right'
    })
}

export function handleServerError(errors) {
    if (errors && ((errors.request && errors.request.status && errors.request.status === 404) || (errors.status && errors.status === 404))) {
        notFound();
        return false;
    }

    if (errors.data) {
        const {message} = errors.data
        serverError(message);
    } else if (errors.message) {
        serverError(errors.message);
    } else {
        serverError("Please refresh the page and try again!");
    }
}

export function handleRoomServerError(errors) {
    if (errors && ((errors.request && errors.request.status && errors.request.status === 404) || (errors.status && errors.status === 404))) {
        notFound();
        return false;
    }

    if (errors.data) {
        const {message} = errors.data
        serverError(message);
    } else {
        serverError("Please refresh the page and try again!");
    }
}

export function handleEventServerError(errors) {
    if (errors && ((errors.request && errors.request.status && errors.request.status === 404) || (errors.status && errors.status === 404))) {
        notFound();
        return false;
    }

    if (errors.data) {
        const {message} = errors.data
        serverError(message);
    } else {
        serverError("Please refresh the page and try again!");
    }
}
