import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  base: 'admin',
  mode: 'history',
  routes: [
    {
      path: "/invitations/:id",
      name: "invitations",
      component: () => import("@/view/pages/invitations"),
      props: true,
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/dashboard2",
          name: "dashboard2",
          component: () => import("@/view/pages/dashboards/SuperUser2")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
        },
        //users
        {
          path: "/users",
          name: "userList",
          component: () => import("@/view/pages/users"),
        },
        {
          path: "/users/:type/:id?",
          name: "userForm",
          component: () => import("@/view/pages/users/create"),
        },

        //Sub User
        {
          path: "/sub/users",
          name: "subuserList",
          component: () => import("@/view/pages/users/subUsers"),
        },
        {
          path: "/sub/users/:type/:id?",
          name: "subuserForm",
          component: () => import("@/view/pages/users/subUsers/create.vue"),
        },

        //guest user
        {
          path: "/guest/users",
          name: "guestUserList",
          component: () => import("@/view/pages/users/guestUsers"),
        },
        {
          path: "/guest/users/:type/:id?",
          name: "guestUserForm",
          component: () => import("@/view/pages/users/guestUsers/create"),
        },

        //guests
        {
          path: "/guests",
          name: "guestList",
          component: () => import("@/view/pages/guests"),
        },
        {
          path: "/guests/:type/:id?",
          name: "guestForm",
          component: () => import("@/view/pages/guests/create"),
        },


        {
          path: "/sub/guest/user",
          name: "subGuestUserList",
          component: () => import("@/view/pages/users/guestUsers/subGuestUser"),
        },
        {
          path: "/sub/guest/user/:type/:id?",
          name: "subGuestUserForm",
          component: () => import("@/view/pages/users/guestUsers/subGuestUser/create"),
        },

        {
          path: "/leads",
          name: "leads",
          component: () => import("@/view/pages/leads"),
        },

        //events
        {
          path: "/events",
          name: "events",
          component: () => import("@/view/pages/events"),
        },
        {
          path: "/events/:type/:id?",
          name: "eventForm",
          component: () => import("@/view/pages/events/form"),
        },
        {
          path: "/event/attachments",
          name: "attachments",
          component: () => import("@/view/pages/events/attachments"),
        },

        //roles
        {
          path: "/roles",
          name: "roleList",
          component: () => import("@/view/pages/roles"),
        },
        {
          path: "/roles/:type/:id?",
          name: "roleForm",
          component: () => import("@/view/pages/roles/create"),
        },

        {
          path: "/company/profiles",
          name: "companyProfile",
          component: () => import("@/view/pages/companyProfiles"),
        },
        {
          path: "/user/company/profiles",
          name: "userCompanyProfile",
          component: () => import("@/view/pages/companyProfiles/superUser"),
        },
        //rooms
        {
          path: "/rooms",
          name: "room",
          component: () => import("@/view/pages/rooms"),
        },
        {
          path: "/rooms/:type/:id?",
          name: "eventForm",
          component: () => import("@/view/pages/rooms/create"),
        },

        {
          path: "/term/conditions",
          name: "termConditions",
          component: () => import("@/view/pages/terms"),
        },
        //other charges
        {
          path: "/other/charges",
          name: "otherChargeList",
          component: () => import("@/view/pages/otherCharges"),
        },
        {
          path: "/other/charges/:type/:id?",
          name: "otherChargeForm",
          component: () => import("@/view/pages/otherCharges/create.vue"),
        },
        //assets
        {
          path: "/assets",
          name: "assetList",
          component: () => import("@/view/pages/assets"),
        },
        {
          path: "/assets/:type/:id?",
          name: "assetForm",
          component: () => import("@/view/pages/assets/create.vue"),
        },
        //properties
        {
          path: "/properties",
          name: "propertyList",
          component: () => import("@/view/pages/properties/index.vue"),
        },
        {
          path: "/properties/:type/:id?",
          name: "propertyForm",
          component: () => import("@/view/pages/properties/create"),
        },
        //halls
        {
          path: "/halls",
          name: "hallList",
          component: () => import("@/view/pages/halls/index.vue"),
        },
        {
          path: "/halls/:type/:id?",
          name: "hallForm",
          component: () => import("@/view/pages/halls/create"),
        },
        //decors
        {
          path: "/decors",
          name: "decorList",
          component: () => import("@/view/pages/decors"),
        },
        {
          path: "/decors/:type/:id?",
          name: "decorForm",
          component: () => import("@/view/pages/decors/create"),
        },
        {
          path: "/fnb",
          name: "fnb",
          component: () => import("@/view/pages/fnb"),
        },
        {
          path: "/fnb/packages",
          name: "fnb.package",
          component: () => import("@/view/pages/fnb"),
        },
        {
          path: "/fnb/food/menus",
          name: "fnb.foodMenus",
          component: () => import("@/view/pages/fnb"),
        },
        {
          path: "guest/user/vendors",
          name: "guestVendor",
          component: () => import("@/view/pages/users/guestUsers/vendors"),
        },

        //vendors
        {
          path: "/vendors",
          name: "vendorList",
          component: () => import("@/view/pages/users/guestUsers/vendors"),
        },
        {
          path: "/vendors/:type/:id?",
          name: "vendorForm",
          component: () => import("@/view/pages/users/guestUsers/vendors/create"),
        },

        //todolists
        {
          path: "/todo/lists",
          name: "todoList",
          component: () => import("@/view/pages/users/guestUsers/todoLists"),
        },
        {
          path: "/todo/lists/:type/:id?",
          name: "todoForm",
          component: () => import("@/view/pages/users/guestUsers/todoLists/create"),
        },


        {
          path: "/guest/rooms",
          name: "guestRoomList",
          component: () => import("@/view/pages/users/guestUsers/rooms"),
        },
        {
          path: "/guest/rooms/:type/:id?",
          name: "guestRoomForm",
          component: () => import("@/view/pages/users/guestUsers/rooms/create"),
        },

        //itineraries
        {
          path: "/itineraries",
          name: "itineraryList",
          component: () => import("@/view/pages/users/guestUsers/itineraries"),
        },
        {
          path: "/itineraries/:type/:id?",
          name: "itineraryForm",
          component: () => import("@/view/pages/users/guestUsers/itineraries/create"),
        },

        //groups
        {
          path: "/groups",
          name: "groupList",
          component: () => import("@/view/pages/groups"),
        },
        {
          path: "/groups/:type/:id?",
          name: "groupForm",
          component: () => import("@/view/pages/groups/create"),
        },

        {
          path: "/food/menus/import",
          name: "foodMenuImport",
          component: () => import("@/view/pages/fnb/foodMenus/importExcel.vue"),
        },
        {
          path: "/import/leads",
          name: "leadImport",
          component: () => import("@/view/pages/leads/importExcel.vue"),
        },
        {
          path: "/import/bookings",
          name: "bookingImport",
          component: () => import("@/view/pages/bookings/importExcel.vue"),
        },

        //brochures
        {
          path: "/brochures",
          name: "brochureList",
          component: () => import("@/view/pages/brochures"),
        },
        {
          path: "/brochures/:type/:id?",
          name: "brochureForm",
          component: () => import("@/view/pages/brochures/create"),
        },

        {
          path: "/calendar",
          name: "calendar",
          component: () => import("@/view/pages/calendars"),
        },

        //quotations
        {
          path: "/quotation",
          name: "quotationList",
          component: () => import("@/view/pages/quotations"),
        },
        {
          path: "/quotation/detail/:id?",
          name: "quotationShowForm",
          component: () => import("@/view/pages/bookings/preview"),
        },
        {
          path: "/quotation/:type/:id?",
          name: "quotationForm",
          component: () => import("@/view/pages/quotations/create"),
        },

        //quotations
        {
          path: "/invoice",
          name: "invoiceList",
          component: () => import("@/view/pages/quotations"),
        },
        {
          path: "/invoice/:type/:id",
          name: "invoiceForm",
          component: () => import("@/view/pages/quotations/create"),
        },


        //bookings
        {
          path: "/bookings",
          name: "bookingList",
          component: () => import("@/view/pages/bookings"),
        },
        {
          path: "/bookings/detail/:id?",
          name: "bookingShowForm",
          component: () => import("@/view/pages/bookings/preview"),
        },
        {
          path: "/bookings/:type/:id?",
          name: "bookingForm",
          component: () => import("@/view/pages/bookings/create"),
        },

        {
          path: "/my/wallet",
          name: "wallet",
          component: () => import("@/view/pages/profile/wallet"),
        },

        //bounce event
        {
          path: "/bounce/events",
          name: "bounceEventList",
          component: () => import("@/view/pages/events/bounce"),
        },
        {
          path: "/bounce/events/:type/:id?",
          name: "bounceEventForm",
          component: () => import("@/view/pages/events/bounce/create"),
        },

        //bounce event
        {
          path: "/spu/share/bounce/events",
          name: "spuShareBounceEventList",
          component: () => import("@/view/pages/events/bounce/share"),
        },
        {
          path: "/spu/share/bounce/events/:type/:id?",
          name: "spuShareBounceEventForm",
          component: () => import("@/view/pages/events/bounce/share/create"),
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "admin-login",
          path: "/login/admin",
          component: () => import("@/view/pages/auth/Login")
        },
      ]
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: () => import("@/view/pages/privacy"),
    },
    {
      path: "/terms-and-condition",
      name: "PrivacyPolicy",
      component: () => import("@/view/pages/terms-and-condition"),
    },
    {
      path: "/data-production",
      name: "dataProduction",
      component: () => import("@/view/pages/data-production"),
    },
    {
      path: "/contact-us",
      name: "contactUs",
      component: () => import("@/view/pages/contacts"),
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error.vue")
    },
  ]
});
