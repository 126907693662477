import { client } from "../core/services/Request";

export function handleGlobalRequestLoader({ dispatch }, baseURL) {
  client.interceptors.request.use(
    function (successRes) {
      successRes.baseURL = `${ baseURL }/api`;
      if ( successRes.method !== "get" && ( successRes.data && successRes.data.ignore_request > 0 ) ) {
        return successRes;
      } else if ( successRes.method === "get" && ( successRes.params && successRes.params.ignore_request > 0 ) ) {
        return successRes;
      }

      dispatch('addPendingRequests');
      if ( successRes.data === undefined ) {
        dispatch('removePendingRequests');
        return Promise.resolve(successRes);
      }

      return successRes;
    },
    function (e) {
      dispatch('removePendingRequests');
      return Promise.reject(e);
    }
  );

  client.interceptors.response.use(
    (successResponseData) => {
      dispatch('removePendingRequests');
      return Promise.resolve(successResponseData);
    },
    error => {
      if ( error.response.status === 403 ) {
        window.location.href = process.env.VUE_APP_ADMIN_URL + '/error';
      }
      dispatch('removePendingRequests');
      return Promise.reject(error);
    }
  );
}
