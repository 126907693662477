import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    state: {
        pendingRequests: 0,
        dataMatch: 0,
    },
    mutations: {
        ADD_PENDING_REQUESTS(state, payload) {
            state.pendingRequests = state.pendingRequests + 1;
        },

        REMOVE_PENDING_REQUESTS(state, payload) {
            state.pendingRequests = (state.pendingRequests > 0) ? state.pendingRequests - 1 : 0;
        },

        ADD_DATA_MATCH_REQUESTS(state, payload) {
            state.dataMatch = state.dataMatch + 1;
        },

        REMOVE_DATA_MATCH_REQUESTS(state, payload) {
            state.dataMatch = 0;
        },
    },
    actions: {
        addPendingRequests(context, payload) {
            context.commit('ADD_PENDING_REQUESTS', payload);
        },

        removePendingRequests(context, payload) {
            context.commit('REMOVE_PENDING_REQUESTS', payload);
        },

        addDatMatchRequests(context, payload) {
            context.commit('ADD_DATA_MATCH_REQUESTS', payload);
        },

        removeDataMatchRequests(context, payload) {
            context.commit('REMOVE_DATA_MATCH_REQUESTS', payload);
        },
    },
    getters: {
        pendingRequests: state => state.pendingRequests,
        dataMatch: state => state.dataMatch,
    },
}
