import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import CKEditor from 'ckeditor4-vue';
import _ from 'lodash';
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
// import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import PolarisVue from '@hulkapps/polaris-vue';
import '@hulkapps/polaris-vue/dist/polaris-vue.css';
import {
  itemAdded,
  itemUpdated,
  itemDeleted,
  itemEditFails,
  handleServerError,
  setProperty,
  invitationSend,
  importSuccess,
  handleEventServerError,
  handleRoomServerError,
  priceAddedToWalletSuccessfully,
  contactSuccess, sendQuotation, notify,
} from '@/util/notify';

import { handleGlobalRequestLoader } from "./util/handleGlobalRequestLoader";
import {
  hasPermission,
  hasRole,
  authUserPermission,
  setPropertyId,
  dateConvert,
  dateFormat,
  numberToLocationString,
  checkSuperUserCompanyProfile,
  timeFormat,
  parentUserExist,
  removeSymbols,
  timeFormatA,
} from "./util/globalFunction";
import "./global.scss";
import moment from "moment-timezone";
import ApexCharts from 'vue-apexcharts';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';

Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);

Vue.use(VueToast);
Vue.use(CKEditor);
Vue.use(PolarisVue);

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

// register globally
Vue.component('apexchart', ApexCharts);

Vue.config.productionTip = false;

window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;

Vue.prototype.moment = moment();
Vue.prototype.$global = {
  hasPermission: (permission) => hasPermission(permission),
  hasRole: (role) => hasRole(role),
  itemAdded: (message) => itemAdded(message),
  itemUpdated: (message) => itemUpdated(message),
  sendQuotation: (message) => sendQuotation(message),
  itemEditFails: () => itemEditFails(),
  notify: (message) => notify(message),
  itemDeleted: () => itemDeleted(),
  setProperty: () => setProperty(),
  handleServerError: (error) => handleServerError(error),
  authUserPermission: () => authUserPermission(),
  dateConvert: (date) => dateConvert(date),
  dateFormat: (date) => dateFormat(date),
  invitationSend: () => invitationSend(),
  importSuccess: () => importSuccess(),
  handleEventServerError: (error) => handleEventServerError(error),
  handleRoomServerError: (error) => handleRoomServerError(error),
  numberToLocationString: (number) => numberToLocationString(number),
  priceAddedToWalletSuccessfully: () => priceAddedToWalletSuccessfully(),
  contactSuccess: () => contactSuccess(),
  checkSuperUserCompanyProfile: () => checkSuperUserCompanyProfile(),
  timeFormat: (time) => timeFormat(time),
  parentUserExist: () => parentUserExist(),
  removeSymbols: (string) => removeSymbols(string),
  timeFormatA: (time) => timeFormatA(time),
};


// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.prototype._ = _;

Vue.directive('numericOnly', {
  bind(el, binding, vnode) {
    el.addEventListener('keydown', (e) => {
      if ( [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        ( ( e.keyCode == 65 || e.keyCode == 86 || e.keyCode == 67 ) && ( e.ctrlKey === true || e.metaKey === true ) ) ||
        // Allow: Ctrl+X
        ( e.keyCode === 88 && e.ctrlKey === true ) ||
        // Allow: home, end, left, right
        ( e.keyCode >= 35 && e.keyCode <= 39 ) ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ( ( e.shiftKey || ( e.keyCode < 48 || e.keyCode > 57 ) ) && ( e.keyCode < 96 || e.keyCode > 105 ) ) {
        e.preventDefault();
      }
    });
  }
});

// Vue.directive('numericOnly', {
//   bind(el, binding, vnode) {
//     el.addEventListener('keydown', (e) => {
//       if ( [8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
//         ( e.keyCode === 65 && e.ctrlKey === true ) ||
//         ( e.keyCode === 67 && e.ctrlKey === true ) ||
//         ( e.keyCode === 88 && e.ctrlKey === true ) ||
//         ( e.keyCode >= 35 && e.keyCode <= 39 ) ) {
//         return;
//       }
//       if ( ( e.shiftKey || ( e.keyCode < 48 ) ) && ( e.keyCode < 96 || e.keyCode > 105 ) ) {
//         e.preventDefault();
//       }
//     });
//     el.addEventListener('paste', (e) => {
//
//       // Get pasted data via clipboard API
//       var clipboardData = e.clipboardData || window.clipboardData;
//       var pastedData = clipboardData.getData('Text');
//       var regex = /^[\d]+$/;
//       if ( regex.test(pastedData) ) {
//         return false;
//       }
//
//     });
//
//   }
// });

export const bus = new Vue();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  created() {
    handleGlobalRequestLoader(store, process.env.VUE_APP_API_URL);
  },
}).$mount("#app");
