import { getToken, destroyToken } from "../core/services/jwt.service";
import _ from 'lodash';
import moment from "moment-timezone";
import store from "@/core/services/store";

export function getAuthUser() {
  let user = getToken();

  user = ( ( user && user !== '' ) ? user : {} );

  if ( Object.keys(user).length <= 0 ) {
    destroyToken();
    return {};
  }
  return user;
}

export function hasRole(role) {
  const user = getAuthUser();
  const roles = ( user && user.roles ) ? user.roles : {};
  if ( !getEventId() && user.roles.length > 1 ) {
    return role === "SubGuestUser";
  }
  if ( getEventId() && user.roles.length > 1 ) {
    const fRole = _.findIndex(roles, { name: role });
    if ( fRole !== -1 ) {
      let ROLE = _.filter(roles[fRole].events, (item) => item.role_id === roles[fRole].id);
      let USER = _.filter(ROLE, (item) => item.user_id === user.id);
      let EVENT = _.filter(USER, (item) => item.event_id == getEventId());
      return EVENT.length > 0;
    }
  }
  const index = _.findIndex(roles, { name: role });
  return ( index >= 0 );
}

export function hasPermission(permission) {
  const user = getAuthUser();
  const role = ( user && user.roles ) ? user.roles : [];
  let permissions = [];
  if ( hasRole('SubUser') ) {
    permissions = user ? user.permissions : [];
    if ( permissions ) {
      return _.findIndex(permissions, { permission: permission }) !== -1;
    }
  } else {
    if ( role.length > 0 ) {
      let allPermission = [];
      role.map(item => allPermission.push(...item.permissions));
      return _.findIndex(allPermission, { permission: permission }) !== -1;
    }
  }
  return false;
}

export function authUserPermission() {
  const user = getAuthUser();
  const role = ( user && user.roles ) ? user.roles : [];

  if ( role.length > 0 ) {
    let allPermission = [];
    role.map(item => allPermission.push(...item.permissions));
    return allPermission;
  }

  return false;
}

export function setPropertyId(id) {
  return localStorage.setItem('property_id', id);
}

export function getPropertyId() {
  if ( localStorage.getItem('property_id') ) {
    return localStorage.getItem('property_id');
  }
  return false;
}

export function setEventId(id) {
  return localStorage.setItem('event_id', id);
}

export function getEventId() {
  if ( localStorage.getItem('event_id') ) {
    return localStorage.getItem('event_id');
  }
  return false;
}

export function removeStorageItem(item) {
  if ( localStorage.getItem(item) ) {
    return localStorage.removeItem(item);
  }
  return false;
}

export function dateConvert(date) {
  if ( date ) {
    return moment(date).format("DD/MM/YY hh:mm");
  }
  return "";
}

export function dateFormat(date) {
  if ( date ) {

    return moment(date).format("DD/MM/YY");
  }
  return "";
}

export function numberToLocationString(number) {
  if ( number ) {
    return Number(number).toLocaleString('en-IN', { minimumFractionDigits: 2 });
  }
  return 0;
}

export function checkSuperUserCompanyProfile() {
  const user = getAuthUser();
  return user.is_company_profile_active;
}

export function timeFormat(time) {
  /*if (time) {
      // return moment(time, [moment.ISO_8601, 'hh:mm']).format("hh:mm A")
  }*/
  return time;
}


export function timeFormatA(time) {
  if ( time ) {
    return moment(time, [moment.ISO_8601, 'hh:mm']).format("hh:mm A");
  }
}

export function parentUserExist() {
  const user = getAuthUser();
  return user.parent_user_id;
}

export function removeSymbols(string) {
  const str = string;
  return str.replace(/[^a-zA-Z ]/g, " ");
}
