import Vue from 'vue';
import Vuex from 'vuex';
import { request } from "../Request";

Vue.use(Vuex);

export default {
  state: {
    getPropertyList: [],
    getEventList: [],
    getActiveProperty: null,
    getEventByUserList: [],
    getActiveEvent: null,
    checkPendingEventData: 0,
    checkPendingRoomData: 0,
    checkFinancialPendingData: 0,
  },
  mutations: {
    GET_PROPERTY_LIST(state, payload) {
      state.getPropertyList = payload;
    },
    GET_EVENT_LIST(state, payload) {
      state.getEventList = payload;
    },
    GET_EVENT_BY_USER_LIST(state, payload) {
      state.getEventByUserList = payload;
    },
    GET_ACTIVE_EVENT_LIST(state, payload) {
      if ( payload ) {
        localStorage.setItem("event_id", payload.id);
      }
      state.getActiveEvent = payload;
    },
    GET_ACTIVE_PROPERTY_LIST(state, payload) {
      if ( payload ) {
        localStorage.setItem("property_id", payload.id);
      }
      state.getActiveProperty = payload;
    },
    CHECK_PENDING_EVENT_DATA(state, payload) {
      state.checkPendingEventData = payload;
    },
    CHECK_PENDING_ROOM_DATA(state, payload) {
      state.checkPendingRoomData = payload;
    },
    CHECK_PENDING_FINANCIAL_DATA(state, payload) {
      state.checkFinancialPendingData = payload;
    },
  },
  actions: {
    getPropertyList(context, payload) {
      request({
        url: '/dropdowns/properties',
        method: 'post',
      })
      .then((response) => {
        const { data } = response.data;
        context.commit('GET_PROPERTY_LIST', data);
      })
      .catch((error) => {
      });
    },
    getActiveProperty(context, payload) {
      context.commit('GET_ACTIVE_PROPERTY_LIST', payload);
    },
    getActiveEvent(context, payload) {
      context.commit('GET_ACTIVE_EVENT_LIST', payload);
    },
    getEventList(context, payload) {
      request({
        url: '/dropdowns/events',
        method: 'post',
      })
      .then((response) => {
        const { data } = response.data;
        context.commit('GET_EVENT_LIST', data);
      })
      .catch((error) => {
      });
    },
    getEventByUserList(context, payload) {
      request({
        url: '/dropdowns/event/by/user',
        method: 'post',
      })
      .then((response) => {
        const { data } = response.data;
        context.commit('GET_EVENT_BY_USER_LIST', data);
      })
      .catch((error) => {
      });
    },
    checkPendingEventData(context, payload) {
      context.commit('CHECK_PENDING_EVENT_DATA', payload);
    },
    checkPendingRoomData(context, payload) {
      context.commit('CHECK_PENDING_ROOM_DATA', payload);
    },
    checkFinancialPendingData(context, payload) {
      context.commit('CHECK_PENDING_FINANCIAL_DATA', payload);
    },
  },
  getters: {
    getPropertyList: state => state.getPropertyList,
    getEventList: state => state.getEventList,
    getActiveProperty: state => state.getActiveProperty,
    getEventByUserList: state => state.getEventByUserList,
    getActiveEvent: state => state.getActiveEvent,
    checkPendingEventData: state => state.checkPendingEventData,
    checkPendingRoomData: state => state.checkPendingRoomData,
    checkFinancialPendingData: state => state.checkFinancialPendingData,
  },
};
