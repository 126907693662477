<script>
import Treeselect from "@riophae/vue-treeselect";
import {UNCHECKED} from "@riophae/vue-treeselect/src/constants";

export default {
  extends: Treeselect,
  data() {
    return {
      overridesLastNodeId: 0,
    };
  },
  methods: {
    overridesFindValue() {
      if (this.$refs.control) {
        const childRefs = this.$refs.control.$refs;

        if (childRefs["value-container"]) {
          const valueContainer = childRefs["value-container"];

          if (valueContainer.$refs.input) {
            return valueContainer.$refs.input.value;
          }
        }
      }

      return null;
    },
    overridesCheckValueInNodes(value) {
      let childHasValue = false;

      this.traverseAllNodesDFS((node) => {
        if (node.label === value) {
          childHasValue = true;
        }
      });

      return childHasValue;
    },
    select(node) {

      /**
       * Here we override the select(node) method from
       * the library, we will inject a new node if a node
       * doesn't exist and then proxy this method to the original!
       */
      const value = this.overridesFindValue();

      if (typeof value === "string" && value.length === 0) {
        // This function gets called internally a lot, so we need
        // to make sure it's proxied when there is no value
        this.resetSearchQuery();
        return Treeselect.mixins[0].methods.select.call(this, node);
      }

      if (value && value !== "") {
        if (this.overridesCheckValueInNodes(value)) {
          // If there is a value, we just fallback to the default function
          this.resetSearchQuery();
          return Treeselect.mixins[0].methods.select.call(this, node);
        }
      }

      if (this.$attrs['create-tag']) {

        /**
         * Finally, we can emit a "fallback" event and
         * in your parrent you can call your appendNode function.
         */
        const id = `new-node-${++this.overridesLastNodeId}`;
        this.$emit("new-node", { value, id });

        /**
         * Additionally, to make the select select our value
         * we need to "emit" it to v-model as well
         */
        this.$emit("input", this.value ? [...this.value, value] : [value]);

        /**
         * Finally, let's reset the input
         */
        this.resetSearchQuery();
      } else {


        if (this.single) {
          this.clear()
        }

        const nextState = this.multiple && !this.flat
          ? this.forest.checkedStateMap[node.id] === UNCHECKED
          : !this.isSelected(node)

        if (nextState) {
          this._selectNode(node)
        } else {
          this._deselectNode(node)
        }

        this.buildForestState()

        if (nextState) {
          this.$emit('select', node.raw, this.getInstanceId())
        } else {
          this.$emit('deselect', node.raw, this.getInstanceId())
        }

        if (this.localSearch.active && nextState && (this.single || this.clearOnSelect)) {
          this.resetSearchQuery()
        }

        if (this.single && this.closeOnSelect) {
          this.closeMenu()

          // istanbul ignore else
          if (this.searchable) {
            this._blurOnSelect = true
          }
        }
      }
    },
  },
};
</script>
