<template>
    <b-modal id="confirm" v-model="dialog" :title="title" :ok-title="options.primaryBtnText" cancel-variant="danger"
             @ok="agree"
             @cancel="cancel"
             :cancel-title="options.secondaryBtnText">
        {{message}}
    </b-modal>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
            destructive: false,
            width: 500,
            zIndex: 9999,
            primaryBtnText: 'Ok',
            secondaryBtnText: 'Cancel',
        }
    }),
    methods: {
        open(title, message, options) {
            this.dialog = true
            this.title = title
            this.message = message
            this.options = Object.assign(this.options, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>
